import type { MaybeRef } from "@vueuse/core";

export const useStringToColor = (
  str: MaybeRef<string>,
  options: { saturation?: number; luminance?: number } = {},
) => {
  return computed(() => {
    const unwrappedString = unref(str);

    const hue =
      [...unwrappedString].reduce((_hash, _, index) => {
        _hash = unwrappedString.charCodeAt(index) + ((_hash << 5) - _hash);

        return _hash;
      }, 0) % 360;

    const saturation = options.saturation ?? 50;
    const luminance = options.luminance ?? 60;

    return `hsl(${hue}, ${saturation}%, ${luminance}%)`;
  });
};
