<template>
  <app-image
    v-if="avatarUrl"
    :alt="alt ?? name ?? ''"
    class="shrink-0 aspect-square object-cover"
    :class="{ 'rounded-full': !tile, rounded: tile }"
    :height="size"
    loading="lazy"
    :src="avatarUrl ?? ''"
    :width="size"
  />

  <p
    v-else
    :aria-label="name ?? ''"
    class="shrink-0 @container"
    v-bind="placeholderBinding"
  >
    <span class="text-xs @[48px]:text-base @[96px]:text-2xl">{{
      initials
    }}</span>
  </p>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    avatarUrl?: string | null;
    name?: string | null;
    size?: number;
    tile?: boolean;
    alt?: string;
  }>(),
  {
    avatarUrl: "",
    name: "",
    size: 65,
    alt: undefined,
  },
);

const initials = computed(() => {
  if (!properties.name) return "";

  return properties.name
    ?.split(" ")
    .map((part) => part[0]?.trim().toUpperCase())
    .slice(0, 2)
    .join("");
});

const placeholderBinding = computed(() => {
  return {
    class:
      "rounded-full aspect-square inline-flex items-center justify-center text-white font-normal",
    style: {
      width: `${properties.size}px`,
      backgroundColor: useStringToColor(properties.name ?? "").value,
    },
  };
});
</script>
